body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loptica-container {
  display: inline;
  margin-right: 5px;
}

.loptica {
  width: 20px;
  height: 20px;
}

.features-slika {
  width: 100px;
  height: 100px;
}

.features-slika-zica {
  width: 100px;
  height: 100px;
  padding: 15px;
}
